<template>
  <div id="video-save">
    <!-- <v-toolbar color="grey lighten-2" > -->
    <v-toolbar class="gradientBG">

      <div class="pa-0 d-flex align-center">
        <a href="/" class="d-flex align-center logo" :class="{'logo-mobile':$vuetify.breakpoint.smAndDown}">
          <img class="logo mr-2" src="@/assets/Youtorial-white.png">
        </a>

        <strong class="white--text text--darken-2 ml-1 page-title headline">Create</strong>
      </div>

      <v-spacer></v-spacer>

      <!-- <div class="pa-0 d-flex align-center"> -->
        <!-- Publish Button -->
        <!-- <v-btn text class="grey--text text--darken-3" @click="$router.push({name:'watch', params: {videoId: videoId}})" v-if="$vuetify.breakpoint.smAndDown">
          <v-icon class="ml-1">remove_red_eye</v-icon>
        </v-btn>
        <v-btn text class="grey--text text--darken-3" @click="$router.push({name:'watch', params: {videoId: videoId}})" v-else>
          Preview
          <v-icon class="ml-1">remove_red_eye</v-icon>
        </v-btn> -->
        <!-- Publish Button -->
        <!-- <v-btn dark class="grey darken-3 mx-2" @click="publishVideo">
          Publish
          <v-icon class="ml-1">dynamic_feed</v-icon>
        </v-btn> -->
      <!-- </div> -->

      <!-- <div class="space-tab"> | </div> -->

      <div class="flex-auto d-flex align-center" v-if="$vuetify.breakpoint.mdAndUp">

        <div class="pa-0 d-flex align-center">
          <v-tooltip bottom v-if="autoSave">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text :color="autoSaved === 'success' ? 'success' :'grey'" @click="toggleAutoSave" >
                <template v-if="autoSaved == 'inprogress'">
                  saving...
                  <v-icon class="ml-2">cloud_upload</v-icon>
                </template>
                <template v-if="autoSaved == 'success'">
                  saved!
                  <v-icon class="ml-2">cloud_done</v-icon>
                </template>
                <template v-if="!autoSaved  ||  autoSaved == 'done'">
                  every 30 sec.
                  <v-icon class="ml-2">cloud_queue</v-icon>
                </template>
              </v-btn>
            </template>
            <span>Turn AutoSave: Off</span>
          </v-tooltip>

          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text color="white darken-1" @click="toggleAutoSave">
                AutoSave
                <v-icon class="ml-2">cloud_off</v-icon>
              </v-btn>
            </template>
            <span>Turn AutoSave: On</span>
          </v-tooltip>

          <!-- Manual Save Button -->
          <v-btn dark color="primary darken-1 mr-4" @click="saveNow">
            Save
            <v-icon class="ml-2">save</v-icon>
          </v-btn>
        </div>


        <div class="space-tab"> | </div>

        <v-btn text class="" color="black" @click="$router.push({name:'home'})">
          Exit
        </v-btn>
      </div>

      <div class="" v-else>
        <v-btn text class="mr-2" color="black" @click="$router.push({name:'home'})">
          <!-- <v-icon>close</v-icon> -->
          Exit
        </v-btn>
        <v-btn dark color="primary darken-1 ma-0 px-2" @click="saveNow"  style="min-width:auto">
          <!-- <v-icon class="">save</v-icon> -->
          save
        </v-btn>
      </div>

    </v-toolbar>


    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      dark
    >
      {{ snackbar.message }}
      <v-btn
        text
        color="white"
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>




  </div>
</template>

<script>


export default {
  name: "video-save",
  props: ['autoSaved', 'videoId'],
  data(){
    return{
      autoSave:false,
        snackbar: {'show':false},
    }
  },
  watch:{
    timeIn:function(newV,oldV){
      // console.log('timeIn', newV.timeupdate, oldV.timeupdate, this.time);
      if(newV.timeupdate !== undefined){
        let newTime = Math.round((newV.timeupdate + Number.EPSILON) * 100) / 100
        this.time = newTime;
      }
    }
  },
  methods: {
    toggleAutoSave(){
      this.autoSave = !this.autoSave;
      this.$emit('toggleAutoSave', this.autoSave)
    },
    saveNow(){
      this.$store.dispatch('saveVideo').then((x) => {
        let vThis = this;
        vThis.snackbar ={
          'show': true,
          'message': 'Youtorial Saved',
          'color':'success',
          'action': 'close'
        }
        vThis.snackbarTimer = setTimeout(() => {
          vThis.snackbar.show = false;
          vThis.autoSaved = 'done';
        },3000)
      }).catch((error)=>{
        let vThis = this;
        vThis.snackbar ={
          'show': true,
          'message': error,
          'color':'error',
          'action': 'close'
        }
      });
    },
    publishVideo(){
      this.$emit('publishVideo', true)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#video-save{
  z-index: 9;
  .v-text-field__details{
    display: none;
  }
  .page-title{
    font-size: 17px;
  }
  .logo{
    max-height: 36px;
  }
  .logo-mobile{
    display: inline-flex;
    max-width: 38px;
    overflow: hidden;
  }
  .space-tab{
    color: #fff;
    opacity: .5;
    margin: 0 15px;
  }
}
</style>
